import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTable, useSortBy } from 'react-table'
import first from 'lodash/first'

import Layout from '../components/layouts/Layout'
import MainBox from '../components/common/MainBox'
import { EnterprisesState, enterprisesSelector, fetchEnterprisesAction } from '../hasura/slices/enterprises'
import { Table, Header, HeaderCell, TextCell, Cell } from '../components/common/Table'
import { usersSelector, UsersState } from '../hasura/slices/users'
import { Enterprises_enterprises } from '../hasura/graphQlQueries/types/Enterprises'
import { OVERFLOW_OPTIONS } from '../interfaces/OverflowOption'

const defaultColumn: any = {
  Cell: TextCell,
}

export default function Enterprises() {
  const dispatch = useDispatch()

  const { accessToken }: UsersState = useSelector(usersSelector)
  const { enterprises }: EnterprisesState = useSelector(enterprisesSelector)

  useEffect(() => {
    if (!accessToken) return

    dispatch(fetchEnterprisesAction(accessToken))
  }, [accessToken])

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: (e: Enterprises_enterprises) => e,
        Cell: (e: { value: Enterprises_enterprises }) => (
          <p className="text-m m-0">
            <span
              onClick={() =>
                window.open(
                  `https://api.radimal.ai/console/data/default/schema/public/tables/enterprises/browse?filter=id%3B%24eq%3B${e.value.id}`,
                  '_blank'
                )
              }
              className="underline pointer"
            >
              {e.value.short_name}
            </span>{' '}
            (ID {e.value.id})
          </p>
        ),
      },
      {
        Header: 'AI Enabled',
        accessor: (e: Enterprises_enterprises) => (e.ai_enabled ? 'True' : ''),
      },
      {
        Header: 'Logo',
        accessor: (e: Enterprises_enterprises) => (e.logo_aws_s3_url ? 'True' : ''),
      },
      {
        Header: 'In Evaluation',
        accessor: (e: Enterprises_enterprises) => (e.in_evaluation ? 'True' : ''),
      },
      {
        Header: 'Default Price Group ID',
        accessor: 'default_price_group_id',
      },
      {
        Header: 'Overflowing',
        accessor: (e: Enterprises_enterprises) => OVERFLOW_OPTIONS[first(e.overflow_rules)?.rule || 0],
      },
      {
        Header: 'Admin Overflow',
        accessor: (e: Enterprises_enterprises) => (e.enterprise_admin_has_overflow_control ? 'True' : ''),
      },
      {
        Header: 'Specialist Overflow',
        accessor: (e: Enterprises_enterprises) => (e.vet_has_overflow_control ? 'True' : ''),
      },
    ],
    []
  )

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      // @ts-ignore
      columns,
      data: enterprises,
      defaultColumn,
    },
    useSortBy
  )

  return (
    <Layout>
      <MainBox defaultPadding>
        <div className="d-flex align-items-start justify-content-between">
          <h4 className="bold">Enterprises</h4>
        </div>

        <div className="max-width-1300px">
          <Table cellSpacing={0} {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column: any, idx: number) => (
                    <HeaderCell noBorder key={idx} {...column.getHeaderProps(column.getSortByToggleProps({}))}>
                      {column.Header && <Header>{column.render('Header')}</Header>}
                    </HeaderCell>
                  ))}
                </tr>
              ))}
            </thead>

            <tbody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row)

                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell: any, idx: number) => {
                      return (
                        <Cell key={idx} {...cell.getCellProps()}>
                          {cell.render('Cell')}
                        </Cell>
                      )
                    })}
                  </tr>
                )
              })}
            </tbody>
          </Table>
        </div>
      </MainBox>
    </Layout>
  )
}
